require('../common/css/normalize.css');
require('./sass/web.sass');

import Cookies from 'js-cookie';

const clamp = (value: number, min = 0, max = 9999999) => {
	return Math.min(Math.max(value, min), max);
};

const throttle = require('lodash.throttle');

declare global {
	interface Window {
		dataLayer: any[];
		LANG_CODE: string;
	}
}

const initGA = () => {
	window.dataLayer = window.dataLayer || [];

	function gtag(...args:unknown[]) {
		window.dataLayer.push(args);
	}

	// @ts-ignore
	gtag('js', new Date());
	// @ts-ignore
	gtag('config', 'G-3WHS7L428Y');


	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.id = 'ze-snippet';
	script.async = true;
	script.src = 'https://static.zdassets.com/ekr/snippet.js?key=eec51bab-e29f-400e-adea-535ab0aed0fd';
	document.getElementsByTagName('head')[0].appendChild(script);

	setTimeout(() => {
		// @ts-ignore
		zE('webWidget', 'setLocale', window.LANG_CODE);
	}, 3000);
}

window.onload = () => {
	const nav = document.getElementById('nav');
	const mobilenav = document.getElementById('mobile-menu-link');
	const langnav = document.getElementById('nav-language-link');
	const mobilemenu = document.getElementById('mobile-menu');
	const langmenu = document.getElementById('language-menu');
	const placeholder = document.getElementById('placeholder');

	document.addEventListener('scroll', throttle(() => {
		if (window.scrollY > 40) {
			nav.classList.add('scrolled');
		}
		else {
			nav.classList.remove('scrolled');
		}
	}, 100));

	mobilenav.addEventListener('click', () => {
		mobilemenu.classList.toggle('open');
		mobilenav.classList.toggle('open');
	});

	langnav.addEventListener('click', () => {
		langmenu.classList.toggle('open');
	});

	if (placeholder) {
		const width = clamp(window.innerWidth, 0, 640);
		const height = Math.ceil(width / 1.777777777);
		const src = placeholder.getAttribute('data-src');

		placeholder.addEventListener('click', () => {
			placeholder.parentElement.innerHTML = `<iframe id="ytplayer" type="text/html" width="${width}" height="${height}" src="${src}" frameborder="0"></iframe>`
		});
	}

	document.getElementById('cookie-banner-accept').addEventListener('click', (e:MouseEvent) => {
		e.preventDefault();
		Cookies.set('banneraccept', '1', { sameSite: 'Lax' });
		document.getElementById('cookie-banner').style.display = 'none';
		initGA();
	});

	document.getElementById('cookie-banner-decline').addEventListener('click', (e:MouseEvent) => {
		e.preventDefault();
		Cookies.set('banneraccept', '0', { sameSite: 'Lax' });
		document.getElementById('cookie-banner').style.display = 'none';
	});

	const bannerState = Cookies.get('banneraccept');

	if (bannerState == undefined) {
		document.getElementById('cookie-banner').style.display = 'block';
	}
	else if (bannerState == '1') {
		initGA();
	}
}

export default {};